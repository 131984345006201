<template>
    <section class="main">
        <img style="width: 200px; height: 200px; margin-top: 150px; margin-bottom: 20px" src="../../assets/img/logo.png" />
        <h2>华立园大门-人脸重置控制面板</h2>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {
        console.log(window.sessionStorage.getItem('powerNum'));
        var powerNum = window.sessionStorage.getItem('powerNum');
        powerNum = powerNum - 1;
        console.log(powerNum);
        localStorage.setItem('powerNum', powerNum);
        if (powerNum > 0) {
            // location.reload();
        }
    }
};
</script>

<style scoped>
.main {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 100%;
}
.list {
    padding: 30px 0;
}
.list p {
    margin-bottom: 20px;
}
a {
    color: #409eff;
}
</style>
