<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="rgb(50, 65, 87)"
            text-color="#fff"
            active-text-color="rgb(32, 160, 255)"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template slot="title">{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{
                                    threeItem.title
                                }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
import { fetchData } from '../../api/index';
export default {
    data() {
        return {
            collapse: false,
            items: [
                {
                    icon: 'el-icon-camera',
                    index: 'faceReset',
                    title: '人脸采集批量重置'
                }
            ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    created() {
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', (msg) => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    methods: {
        powerChange() {
            if (window.sessionStorage.getItem('power') == '2') {
                this.items = [
                    // {
                    //     icon: 'el-icon-refresh',
                    //     index: 'userTransfer',
                    //     title: '用户转职'
                    // },
                    // {
                    //     icon: 'el-icon-camera',
                    //     index: 'faceReset',
                    //     title: '人脸采集批量重置'
                    // },
                    // {
                    //     icon: 'el-icon-sort',
                    //     index: 'exportEnterRecord',
                    //     title: '进出记录导出'
                    // },
                    // {
                    //     icon: 'el-icon-share',
                    //     index: 'exportInviteRecord.',
                    //     title: '邀请来访记录导出'
                    // },
                    {
                        icon: 'el-icon-coordinate',
                        index: 'collegeManage.',
                        title: '班级管理'
                    },
                    {
                        icon: 'el-icon-coordinate',
                        index: 'classManage.',
                        title: '学生管理'
                    },
                    {
                        icon: 'el-icon-guide',
                        index: 'exportOutStaffRecord',
                        title: '学生外出申请列表'
                    },
                    {
                        icon: 'el-icon-sort',
                        index: 'exportOutRecord',
                        title: '学生外出记录导出'
                    }
                ];
            } else if (window.sessionStorage.getItem('power') == '1') {
                this.items = [
                    // {
                    //     icon: 'el-icon-refresh',
                    //     index: 'userTransfer',
                    //     title: '用户转职'
                    // },
                    // {
                    //     icon: 'el-icon-camera',
                    //     index: 'faceReset',
                    //     title: '人脸采集批量重置'
                    // },
                    // {
                    //     icon: 'el-icon-sort',
                    //     index: 'exportEnterRecord',
                    //     title: '进出记录导出'
                    // },
                    // {
                    //     icon: 'el-icon-share',
                    //     index: 'exportInviteRecord.',
                    //     title: '邀请来访记录导出'
                    // },
                    {
                        icon: 'el-icon-coordinate',
                        index: 'classManage.',
                        title: '学生管理'
                    },
                    {
                        icon: 'el-icon-guide',
                        index: 'exportOutStaffRecord',
                        title: '学生外出申请列表'
                    }
                ];
            } else if (window.sessionStorage.getItem('power') == '3') {
                this.items = [
                    // {
                    //     icon: 'el-icon-refresh',
                    //     index: 'userTransfer',
                    //     title: '用户转职'
                    // },
                    // {
                    //     icon: 'el-icon-camera',
                    //     index: 'faceReset',
                    //     title: '人脸采集批量重置'
                    // },
                    // {
                    //     icon: 'el-icon-sort',
                    //     index: 'exportEnterRecord',
                    //     title: '进出记录导出'
                    // },
                    // {
                    //     icon: 'el-icon-share',
                    //     index: 'exportInviteRecord.',
                    //     title: '邀请来访记录导出'
                    // },
                    {
                        icon: 'el-icon-school',
                        index: 'schoolManage.',
                        title: '二级学院管理'
                    },
                    {
                        icon: 'el-icon-s-management',
                        index: 'collegeManage.',
                        title: '班级管理'
                    },
                    {
                        icon: 'el-icon-document-add',
                        index: 'classManage.',
                        title: '学生管理'
                    },
                    {
                        icon: 'el-icon-coordinate',
                        index: 'schoolAdmin.',
                        title: '学校审核员'
                    },
                    {
                        icon: 'el-icon-discount',
                        index: 'collegeAdmin.',
                        title: '二级院校审核员'
                    },
                    {
                        icon: 'el-icon-bell',
                        index: 'outStrategy.',
                        title: '外出策略管理'
                    },
                    {
                        icon: 'el-icon-guide',
                        index: 'exportOutStaffRecord',
                        title: '学生外出申请列表'
                    },
                    {
                        icon: 'el-icon-sort',
                        index: 'exportOutRecord',
                        title: '学生外出记录导出'
                    }
                ];
            }
        }
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
